import React, { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import StudentDiwanLogo from "../../Assets/HomeAssets/StudentDiwanLogo.png";
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";
import sidebarData from "./DataFile/sidebarData.js";
import { FiLogOut } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import smallLogo from "../../Assets/SideBarAsset/smallLogo.png";
import LogoutConfirmationModal from "../Common/LogoutConfirmationModal.js";
import profileIcon from "../../Assets/DashboardAssets/profileIcon.png";
import { toggleSidebar } from "../../Store/Slices/Common/User/reducers/userSlice.js";
import { parentLogout } from "../../Store/Slices/Common/Auth/actions/parentActions.js";
// import useGetUserDetail from "../../Hooks/AuthHooks/Staff/useGetUserDetail.js";

// Updated function to handle more paths
const isActivePath = (path, locationPath) => {
  if (path === "/children" && (
    locationPath.startsWith("/children") ||
    locationPath.startsWith("/checkprogress") ||
    locationPath.startsWith("/childgrade") ||
    locationPath.startsWith("/attendance") ||
    locationPath.startsWith("/teacher")
  )) {
    return true;
  }
  return locationPath.startsWith(path);
};

const SideMenubar = () => {
  const location = useLocation();
  const { t } = useTranslation('prtSidebar'); // Translation hook, using the prtSidebar namespace
  const [openItems, setOpenItems] = useState([]);
  const dispatch = useDispatch();

  // const { userDetail } = useGetUserDetail();

  // useEffect(() => {
  //   const getData = async () => {
  //     await userDetail();
  //   };
  //   getData();
  // }, []);

  const { isOpen, role, userDetails } = useSelector((state) => ({
    isOpen: state.common.user.sidebar.isOpen,
    role: state.common.auth.role,
    userDetails: state?.common.user?.userDetails,
  }));

  const toggleDropdown = (title) => {
    if (openItems.includes(title)) {
      setOpenItems(openItems.filter((item) => item !== title));
    } else {
      setOpenItems([...openItems, title]);
    }
  };

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };

  const confirmLogout = async () => {
    setIsLoggingOut(true);
    try {
      await dispatch(parentLogout());
      setIsLogoutModalOpen(false);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const navigate = useNavigate();

  return (
    <nav
      className={`sticky top-0 transition-all duration-300 h-screen p-1 z-50 bg-white border-r flex flex-col ${isOpen ? "w-[15%]" : "w-[7%]"
        }`}
    >
      <NavLink to="/parent_dash" className="relative flex items-center justify-center border-b pb-1" style={{ zIndex: 1001 }}>
        <img
          src={isOpen ? StudentDiwanLogo : smallLogo}
          alt="Logo"
          className={`transition-width duration-300 ${isOpen ? "w-36 pt-1" : "h-12"
            }`}
        />
        <button onClick={() => dispatch(toggleSidebar())} className="focus:outline-none absolute bottom-0 right-0">
          <div className="p-1 rounded-full text-purple-500 -mr-4 -mb-4 z-40 bg-white border-2">
            {isOpen ? <IoIosArrowBack /> : <IoIosArrowForward />}
          </div>
        </button>
      </NavLink>
      <div className="mt-4 p-2">
        {isOpen && <h2 className="text-gray-500 mb-2 ml-4">{t("Menu")}</h2>} {/* Translated Menu Title */}
        <hr />
        <ul className="mt-1 space-y-2 flex-grow">
          {sidebarData?.map((item, index) => (
            <React.Fragment key={index}>
              {item.items ? (
                <div
                  className={`flex items-center w-full p-2 rounded-lg cursor-pointer ${isActivePath(item.path, location.pathname)
                    ? "bg-purple-100 text-purple-500"
                    : "text-gray-700 hover:bg-gray-100"
                    } ${isOpen ? "justify-between" : "justify-center"}`}
                  onClick={() => toggleDropdown(item.title)}
                >
                  <div className={`flex justify-center items-center`}>
                    <span className={`${!isOpen && "text-xl"}`}>{item.icon}</span>
                    {isOpen && (
                      <span role="presentation" className="ml-3 flex items-center">
                        {t(item.title)} {/* Translation applied for sidebar titles */}
                      </span>
                    )}
                  </div>
                  {isOpen && (
                    <>
                      {openItems.includes(item.title) ? (
                        <MdOutlineKeyboardArrowUp className="ml-2" />
                      ) : (
                        <MdOutlineKeyboardArrowDown className="ml-2" />
                      )}
                    </>
                  )}
                </div>
              ) : (
                <NavLink
                  key={index}
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center p-2 rounded-lg ${isActive || isActivePath(item.path, location.pathname)
                      ? "bg-gradient-to-r from-[#FAECF0] to-[#F3EBFB]"
                      : "text-gray-700 hover:bg-gray-100"
                    } ${isOpen ? "" : "justify-center"}`
                  }
                  style={({ isActive }) =>
                    isActive
                      ? {
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "black",
                        backgroundImage: "linear-gradient(to right, #C83B62, #7F35CD)", // For text gradient
                        background: "linear-gradient(to right, #FAECF0, #F3EBFB)", // For background gradient
                      }
                      : { color: "inherit" } // Keep normal color for inactive links
                  }
                >
                  <span className={`${!isOpen && "text-xl"}`}>{item.icon}</span>
                  {isOpen && (
                    <span role="presentation" className="ml-3">
                      {t(item.title)} {/* Translation applied for individual items */}
                    </span>
                  )}
                </NavLink>
              )}
              {openItems.includes(item.title) && item.items && (
                <ul className="pl-2 space-y-2">
                  {item?.items?.map((subItem, subIndex) => (
                    <NavLink
                      key={subIndex}
                      to={subItem.path}
                      className={({ isActive }) =>
                        `flex items-center p-2 rounded-lg ${isActive ? "text-purple-500 bg-purple-100" : "text-gray-700 hover:bg-gray-100"}`
                      }
                    >
                      {subItem.icon}
                      {isOpen && (
                        <span role="presentation" className="ml-3">
                          {t(subItem.title)} {/* Translation for submenu items */}
                        </span>
                      )}
                    </NavLink>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
      <div
        className={`fixed bottom-1 h-[3rem] flex flex-row items-center justify-center  border-gray-300 ${isOpen ? "w-[14.7%]" : "w-[7%]"
          }`}
        style={{
          transition: "width 0.3s ease",
        }}
      >
        {/* Profile Image */}
        <img
          src={userDetails?.profile || profileIcon}
          alt="Profile"
          className={`${isOpen ? "w-10 h-10" : "w-8 h-8"
            } cursor-pointer rounded-full`}
          onClick={() => navigate('/users/parent/profile')}
        />

        {/* User Details */}
        {isOpen && (
          <div className="flex-1 ml-3">
            <h2 className="font-semibold">
            {userDetails?.fatherName?.charAt(0)?.toUpperCase() + userDetails?.fatherName?.split(' ')[1]?.charAt(0)?.toUpperCase() || "User"}
            </h2>
            <p className="text-gray-500 capitalize text-sm">{role}</p>
          </div>
        )}

        {/* Logout Button */}
        <button
          title={t("Logout")}
          onClick={handleLogout}
          className="mr-3"
          aria-label={t("Logout")}
        >
          <FiLogOut
            className={`${isOpen ? "w-7 h-7" : "w-5 h-5"} text-gray-500`}
          />
        </button>
      </div>

      <LogoutConfirmationModal
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
        onConfirm={confirmLogout}
        loading={isLoggingOut}
      />
    </nav>
  );
};

export default SideMenubar;
